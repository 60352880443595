<template>
  <div class="no-data-found">
    <div class="no-data-found__image">
      <FeatherIcon icon="FrownIcon" size="70"/>
    </div>
    <h3>{{ $t('g.no_data') }}</h3>
  </div>
</template>

<script>
export default {
  name: "NoDataFound"
}
</script>

<style scoped lang="scss" src="./_index.scss"/>
